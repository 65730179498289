import * as React from 'react';

function EspressoMachine(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
      <path d="M19.5833 19.9999H0.41671C0.186663 19.9999 0 19.8133 0 19.5832V16.25C0 16.0199 0.186663 15.8333 0.41671 15.8333H10.0909L12.3826 6.66657H0.416996C0.186948 6.66657 0.000285827 6.47991 0.000285827 6.24986V0.41671C0.000285827 0.186662 0.186948 0 0.416996 0H19.5836C19.8128 0 20.0003 0.186662 20.0003 0.41671V19.5833C20.0003 19.8133 19.8128 20 19.5836 20L19.5833 19.9999ZM0.833491 19.1667H19.1665V0.833634H0.833491V5.83358H12.9166C13.0449 5.83358 13.1657 5.89273 13.2457 5.9936C13.3248 6.09362 13.3516 6.22448 13.3207 6.35116L10.8208 16.3511C10.7749 16.5369 10.6083 16.6669 10.4166 16.6669H0.833505V19.1669L0.833491 19.1667Z" fill="black"/>
      <path d="M5.00024 6.66687H5.83352V8.33357H5.00024V6.66687Z" fill="black"/>
      <path d="M5.90345 16.6668H4.93011C3.13102 16.6668 1.66675 15.2026 1.66675 13.4034V10.4168C1.66675 10.1868 1.85341 10.0001 2.08346 10.0001H8.75017C8.98022 10.0001 9.16688 10.1868 9.16688 10.4168V13.4034C9.16688 15.2025 7.70275 16.6668 5.90352 16.6668H5.90345ZM2.50017 10.8333V13.4033C2.50017 14.7424 3.59016 15.8332 4.93011 15.8332H5.90345C7.24344 15.8332 8.33339 14.7424 8.33339 13.4033V10.8333H2.50017Z" fill="black"/>
      <path d="M2.50022 14.1668H1.2328C0.552845 14.1668 0.000244141 13.6135 0.000244141 12.9342V12.0668C0.000244141 11.3876 0.553524 10.8342 1.2328 10.8342H1.66694V11.6675H1.2328C1.0128 11.6675 0.833664 11.8466 0.833664 12.0666V12.9341C0.833664 13.1549 1.01279 13.3332 1.2328 13.3332H2.50022V14.1668Z" fill="black"/>
      <path d="M17.917 18.3332H14.5837C14.3545 18.3332 14.167 18.1465 14.167 17.9164V16.2497C14.167 16.0197 14.3545 15.833 14.5837 15.833H17.917C18.1462 15.833 18.3337 16.0197 18.3337 16.2497V17.9164C18.3337 18.1465 18.1462 18.3332 17.917 18.3332ZM15.0003 17.4999H17.5002V16.6666H15.0003V17.4999Z" fill="black"/>
    </svg>
  );
}

const MemoEspressoMachine = React.memo(EspressoMachine);
export default MemoEspressoMachine;
