import * as React from "react";

function Informative(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="M6.129 10.803H7.16v-6.22H6.13v6.22zm.533-7.23c.374 0 .68-.305.68-.703a.688.688 0 00-.68-.703.693.693 0 00-.704.703c0 .397.307.704.704.704z"
        fill="#FCFAF8"
      />
      <circle cx={6.5} cy={6.5} r={6} stroke="#FCFAF8" />
    </svg>
  );
}

const MemoInformative = React.memo(Informative);
export default MemoInformative;
