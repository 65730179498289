export const colors = {
  roveBlue: '#aad4d2',
  roveBlack: '#1d1919',
  roveGreen: '#859485',
  roveOffWhite: '#f8f4f1',
  roveOrange: '#f0beaa',
  roveTan: '#c3beb4',
  roveWhite: '#ffffff',
  roveLightBlue: '#70c5c1',
  roveDarkBlack: '#000000',
};
