import * as React from "react";

function Bank(props) {
  return (
    <svg width="50" height="30" viewBox="0 0 50 30" fill="none" {...props}>
      <rect x="1" y="1" width="48" height="28" rx="4" stroke="#1D1919" strokeWidth="2"/>
      <path d="M32.8857 10.2247L25.2193 7.03041L25.2184 7.03027C25.1207 6.98991 25.0111 6.98991 24.9134 7.03027L24.9121 7.03055L17.2457 10.2249V10.2247C17.0968 10.2868 17 10.4322 17 10.5934V11.8711C17 11.9771 17.0421 12.0786 17.1169 12.1535C17.1918 12.2283 17.2933 12.2704 17.3993 12.2704H18.2777V20.4158H17.3993C17.2933 20.4158 17.1918 20.4578 17.1169 20.5327C17.0421 20.6076 17 20.7091 17 20.8151V22.7317C17 22.8376 17.0421 22.9391 17.1169 23.014C17.1918 23.0889 17.2933 23.1309 17.3993 23.1309H32.7321C32.838 23.1309 32.9396 23.0889 33.0144 23.014C33.0893 22.9391 33.1314 22.8376 33.1314 22.7317V20.8151C33.1314 20.7091 33.0893 20.6076 33.0144 20.5327C32.9396 20.4578 32.838 20.4158 32.7321 20.4158H31.8537V12.2704H32.7321C32.838 12.2704 32.9396 12.2283 33.0144 12.1535C33.0893 12.0786 33.1314 11.9771 33.1314 11.8711V10.5934C33.1314 10.4322 33.0345 10.2868 32.8857 10.2247H32.8857ZM32.3328 21.2147V22.3327H17.7988V21.2147H32.3328ZM19.0765 20.4161V12.2708H20.8334V20.4161H19.0765ZM21.6319 20.4161V12.2708H23.3888V20.4161H21.6319ZM24.1874 20.4161V12.2708H25.9443V20.4161H24.1874ZM26.7429 20.4161V12.2708H28.4998V20.4161H26.7429ZM29.2983 20.4161V12.2708H31.0552V20.4161H29.2983ZM32.333 11.472H17.7989V10.8598L25.0659 7.83173L32.333 10.8598V11.472Z" fill="black"/>
      <path d="M24.6663 9.31575V10.1942H23.7879C23.5674 10.1942 23.3885 10.373 23.3885 10.5935C23.3885 10.814 23.5674 10.9928 23.7879 10.9928H26.3433C26.5638 10.9928 26.7426 10.814 26.7426 10.5935C26.7426 10.373 26.5638 10.1942 26.3433 10.1942H25.4649V9.31575C25.4649 9.09528 25.2861 8.91644 25.0656 8.91644C24.8451 8.91644 24.6663 9.09527 24.6663 9.31575V9.31575Z" fill="black"/>
    </svg>
  );
}

const MemoBank = React.memo(Bank);
export default MemoBank;
