import React from 'react';

function BurgerMenu(props) {
  return (
    <svg
      {...props}
      width={27}
      height={19}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 1h26.35M0 9.5h26.35M0 18h26.35' stroke='#1D1919' />
    </svg>
  );
}

const MemoBurgerMenu = React.memo(BurgerMenu);
export default MemoBurgerMenu;
