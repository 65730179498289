import * as React from "react";

function Check(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 12" fill="none" {...props}>
      <path d="M1 5.5l5 5L15.5 1" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

const MemoCheck = React.memo(Check);
export default MemoCheck;
