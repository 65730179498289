import * as React from 'react';

function Calendar(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.048 18.571V5.714H20v13.334a.476.476 0 01-.476.476H4.286a.476.476 0 01-.476-.476V16.19h.952v2.38h14.286z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.81 1.905c0-.263.213-.476.476-.476h15.237c.264 0 .477.213.477.476V5.84c0 .29-.053.577-.156.847l-3.685 9.673a.476.476 0 01-.445.307H.476a.477.477 0 01-.445-.646l3.685-9.672a1.43 1.43 0 00.093-.509V1.905zm.952.476V5.84c0 .29-.053.577-.156.847l-3.439 9.027h14.22l3.567-9.365c.062-.163.094-.335.094-.509V2.381H4.762z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.81 1.905c0-.263.213-.476.476-.476h15.238c.263 0 .476.213.476.476V6.19a.476.476 0 01-.476.477H4.286a.476.476 0 01-.477-.477V1.905zm.952.476v3.333h14.286V2.381H4.762z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.143 0v3.333H6.19V0h.952zM12.38 0v3.333h-.952V0h.953zM17.62 0v3.333h-.953V0h.952z'
        fill='#000'
      />
    </svg>
  );
}

const MemoCalendar = React.memo(Calendar);
export default MemoCalendar;
