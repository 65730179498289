import * as React from 'react';

function ParachuteLinens(props) {
  return (
    <svg width='20' height='12' viewBox='0 0 20 12' fill='none' {...props}>
      <path d="M1.50381 8.66197H1.70508V10.8304C1.70508 10.9689 1.81728 11.081 1.95575 11.081H3.96681C4.10529 11.081 4.21749 10.9689 4.21749 10.8304V8.66197H6.15285V9.89772C6.15285 10.0362 6.26505 10.1484 6.40353 10.1484H15.7824V10.8304C15.7824 10.9689 15.8946 11.0811 16.0331 11.0811H18.0442C18.1827 11.0811 18.2949 10.9689 18.2949 10.8304V8.66199H18.4961C19.3254 8.66199 20 7.9874 20 7.15811C20 6.32883 19.3254 5.65423 18.4961 5.65423H17.5838V5.49687C17.5838 4.90865 17.1052 4.43015 16.517 4.43015L7.2195 4.43C6.63127 4.43 6.15278 4.90853 6.15278 5.49672V5.65407H5.22592V2.82996C5.22592 2.31413 4.80608 1.89445 4.29025 1.89445H3.00777V1.50388C3.00777 0.674596 2.33317 0 1.50388 0C0.674596 0 0 0.674596 0 1.50388V7.15814C0 7.98727 0.674596 8.66187 1.50388 8.66187L1.50381 8.66197ZM17.5836 6.15538H18.4959C19.0487 6.15538 19.4986 6.60511 19.4986 7.15808C19.4986 7.7109 19.0489 8.16079 18.4959 8.16079H17.5836V6.15538ZM17.3329 10.1484C17.4714 10.1484 17.5836 10.0362 17.5836 9.89769V8.66194H17.7933V10.5797H16.2836V10.1483L17.3329 10.1484ZM6.65401 5.49677C6.65401 5.18504 6.90768 4.93139 7.2194 4.93139H16.5169C16.8287 4.93139 17.0823 5.18505 17.0823 5.49677V9.64723L6.65401 9.64739V5.49677ZM3.7161 10.5798H2.20638V8.66209H3.7161V10.5798ZM3.00783 2.39571H4.29031C4.52981 2.39571 4.72461 2.59052 4.72461 2.83001V5.65428H3.00783V2.39571ZM0.501238 1.50378C0.501238 0.950964 0.950969 0.501073 1.50395 0.501073C2.05676 0.501073 2.50666 0.950803 2.50666 1.50378V5.90481C2.50666 6.04329 2.61886 6.15549 2.75733 6.15549H6.15299V8.1607H1.50422C0.9514 8.1607 0.501508 7.71097 0.501508 7.15799L0.501238 1.50378Z" fill="black"/>
    </svg>
  );
}

const MemoParachuteLinens = React.memo(ParachuteLinens);
export default MemoParachuteLinens;
