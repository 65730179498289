import React from 'react';
import { colors } from '@utils/constants/colors';

function Lens(props) {
  return (
    <svg
      width={22}
      height={22}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle
        cx={9.5}
        cy={9.5}
        r={9}
        stroke={props.stroke ?? colors.roveBlack}
      />
      <path d='m16.5 15.5 5 5' stroke={props.stroke ?? colors.roveBlack} />
    </svg>
  );
}

const MemoLens = React.memo(Lens);
export default MemoLens;
